//if objectFit == 'contain', do not want cropping -> Ensure image dimensions don't exceed container
.image-container-fit-contain {
  max-height: 100%;
  max-width: 100%;
}

.image-fill {
  height: 100%;
  width: 100%;
  position: absolute;
  inset: 0;
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: center center;
}

.image-responsive {
  box-sizing: border-box;
  padding: 0;
  border: none;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
}

.object-fit-cover {
  object-fit: cover;
}

.object-fit-contain {
  object-fit: contain;
}

.object-position-top {
  object-position: top;
}

.object-position-bottom {
  object-position: bottom;
}

.object-position-left {
  object-position: left;
}

.object-position-right {
  object-position: right;
}

.object-position-center {
  object-position: center;
}

.object-position-left-top {
  object-position: left top;
}

.object-position-top-left {
  object-position: top left;
}

.object-position-center-bottom {
  object-position: center bottom;
}

.object-position-top-right {
  object-position: top right;
}

.object-position-center-top {
  object-position: center top;
}

.object-position-right-center {
  object-position: right center;
}

.object-position-top-center {
  object-position: top center;
}